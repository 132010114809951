.body {
  min-height: 90vh !important;
  display: flex !important;
  flex-direction: column !important;
  flex: 1;
}

footer {
  margin-top: auto !important;
}

.card-pub {
  overflow: hidden;
  box-shadow: 0 8px 16px 0 rgba(75, 104, 123, 0.2),
    0 2px 4px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  animation: ease-in;
  overflow: hidden;
  border-inline-width: thick !important;
  writing-mode: horizontal-tb !important;
  border-left-color: #258493 !important;
  color: black !important;
  max-width: 85rem;
  margin-bottom: 2rem !important;
}
.card-pub:hover {
  transform: scale(1.005);
}

.card-team-adv {
  overflow: hidden;
  transition: 0.3s;
  box-shadow: 0 8px 16px 0 rgba(75, 104, 123, 0.2),
    0 2px 4px 0 rgba(0, 0, 0, 0.2);
  animation: ease-in;
  min-height: 31rem !important;
  max-width: 50rem !important;
  margin-bottom: 2rem;
}
.card-team-adv:hover {
  transform: scale(1.01);
}
.ad-body {
  padding: 10px;
}

.card-team {
  overflow: hidden;
  transition: 0.3s;
  box-shadow: 0 8px 16px 0 rgba(75, 104, 123, 0.2),
    0 2px 4px 0 rgba(0, 0, 0, 0.2);
  animation: ease-in;

  min-height: 31rem !important;
  max-width: 25rem !important;
  margin-bottom: 2rem;
}
.card-team:hover {
  transform: scale(1.01);
}

.card-news {
  overflow: hidden;
  box-shadow: 0 8px 16px 0 rgba(75, 104, 123, 0.2),
    0 2px 4px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  animation: ease-in;
  overflow: hidden;
  border-inline-width: thick !important;
  writing-mode: horizontal-tb !important;
  border-left-color: #258493 !important;
  color: black !important;

  margin-bottom: 2rem;
  padding: 2px;
}
.card-news:hover {
  transform: scale(1.01);
}

.card-rsc {
  overflow: hidden;
  box-shadow: 0 8px 16px 0 rgba(75, 104, 123, 0.2),
    0 2px 4px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  animation: ease-in;
  max-width: 25rem;
  max-height: 25rem;
  margin-bottom: 2rem;
}
.card-rsc:hover {
  transform: scale(1.01);
}

.bio {
  text-align: justify;
  text-overflow: clip;
  text-wrap: wrap;
}

.card-row {
  display: flex;
  justify-content: center;
  color: #258493 !important;
  margin-bottom: 2rem;
}
.card-title {
  font-size: large;
  font-weight: bolder;
}

.nav-pills {
  --bs-nav-pills-border-radius: var(--bs-border-radius);
  --bs-nav-pills-link-active-color: #fff !important;
  --bs-nav-pills-link-active-bg: #258493 !important;

  color: #fff !important;
}

.nav-link {
  display: block;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  text-decoration: none;
  background: 0 0;
  border: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}

.nav-link:hover {
  display: block;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: rgb(255, 255, 255) !important;
  text-decoration: none;
  background: 0 0;
  border: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
  background-color: #25849376;
}
.txt {
  font-size: larger;
  font-weight: 800;
}
.button {
  background-color: #258493 !important;
  border-color: #258493 !important;
  overflow: hidden !important;
  box-shadow: 0px 0px 5px 0px rgb(82, 81, 81) !important;
}

hr {
  border: none;
  color: #115660 !important;
  border-width: 2px !important;
  margin-bottom: 10px !important;
}
.container {
  max-width: 80rem !important;
  text-align: justify;
  color: rgb(0, 0, 0);
  font-weight: 400 !important;
  font-size: large !important;
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.icon-right {
  padding-left: 5px;
}

.info-bottom {
  display: flex;
  flex-direction: row;
}
h1 {
  font-weight: bolder !important;
  color: #115660 !important;
}

h2 {
  font-weight: bold !important;
  color: #4e4e4e !important;
}

h3 {
  font-weight: 600 !important;
  color: #4e4e4e !important;
}

h4 {
  font-weight: 600 !important;
  color: #313131 !important;
}
.muted {
  color: #707070;
  font-weight: 500 !important;
  font-size: medium !important;
}

.read {
  display: flex;
  justify-content: flex-end;
}
.studentrow {
  display: flex;
  flex-direction: row;
  text-align: left;
}

.text-link-card {
  text-decoration: none !important;
  color: #258493 !important;
}
.avatar-big {
  height: 180px;
  width: 180px;
  max-height: 180px;
  max-width: 180px;
  object-fit: cover;
}

.avatar-big-imt {
  height: 180px;
  width: 180px;
  max-height: 180px;
  max-width: 180px;
  object-fit: contain;
  background-color: #2a4964;
}

.avatar-small {
  height: 50px;
  width: 50px;
}

.avatar-med {
  height: 75px;
  width: 75px;
}

.avatar-img {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.resource-img {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  background-color: white !important;
}

.rsc-big {
  height: 250px;
  width: 250px;
  max-height: 250px;
  max-width: 250px;
  object-fit: contain;

  background-color: white !important;
}

.scholar {
  height: 2rem;
  align-items: center;
}
.link {
  color: #258493 !important;
}

.blank-space {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.ach {
  margin-left: 20px !important;
}
.readmore {
  font-weight: bold;
  color: #258493;
}

.text-link-card {
  text-decoration: none !important;
}

.card-title {
  font-weight: 700 !important;
  color: #3e3e3e !important;
}
.news-top {
  text-align: center;
  max-width: 60rem !important;
}

.newsimg {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  background-color: rgb(255, 255, 255) !important;
  border-color: 'black' !important;
  border-width: 2px !important;
  object-fit: contain;
}

.imgcontainer {
  max-height: 40rem !important;
  height: 35rem !important;
}

.news-text {
  font-size: 1.15em;
  font-weight: 500;
}
.paragraph-container {
  background-color: #fafafa;
  padding: 0 3rem 3rem;
  padding-top: 3rem;
}

.truncated-text {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  max-width: 100%;
}

.goback {
  display: flex;
  justify-content: flex-start;
  color: #113d43 !important;
  font-weight: 600 !important;
  font-size: 1;
  width: fit-content !important;
  margin-top: 15px;
}

.goback-link {
  text-decoration: none;
  color: #1c565e;
}
.navbar-others {
  background-color: #1c565e !important;
}
.navbar-home {
  background-color: #07444b00 !important;
}
nav {
  color: white !important;
}

.landingpage {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  text-align: center;
  height: 800px !important;
  max-height: 500px !important;
  transition: 0.1s;
}

.video-bg {
  height: 900px !important;
  width: 100%;
  max-height: 1000px !important;
  position: absolute;
  top: 0;
  z-index: -1;
  object-fit: cover;
}

.bg-overlay {
  height: 100%;
  width: 100%;
}

.home-text {
  width: 70%;
  height: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  z-index: 2;
}

.welcome {
  margin-top: 5rem;
  font-size: 3.5em;
  color: white;
  font-weight: bold;
  transition: 0.9s;
}
.slogan {
  font-size: 2em;
  color: white;
  font-weight: 500;
}

.welcome-mob {
  margin-top: 5rem;
  font-size: 2.5em;
  color: white;
  font-weight: bold;
  transition: 0.9s;
}
.slogan-mob {
  margin-top: 10px;
  font-size: 1.4em;
  color: white;
  font-weight: 500;
}

.playbut {
  padding-top: 5rem;
  cursor: pointer;
  font-size: 1.5em;
}
.homepage-container {
  margin-top: 3rem;
}

/* .anouncement {
  display: flex;
  text-align: center;
  background-color: rgb(255, 248, 239);
} */
.anouncement {
  display: flex;
  text-align: center;
  background-color: #ffd700; /* Bright yellow background color */
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; /* Font family preference */
  color: #333; /* Default text color */
}

.navbar-nav {
  --bs-nav-link-padding-x: 0;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-navbar-color);
  --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
  --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  font-size: larger;
}

div::-webkit-scrollbar {
  display: none;
}
div::-webkit-scrollbar {
  display: none;
}

.card-topic {
  overflow: hidden;
  box-shadow: 0 8px 16px 0 rgba(75, 104, 123, 0.2),
    0 2px 4px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  animation: ease-in;
  /* border-inline-width: thick !important;
  writing-mode: horizontal-tb !important;
  border-left-color: #4cae4f !important; */
  border-radius: 5px;
  /* height: 350px; */
  /* border-color: #258493 !important; */
  margin-bottom: 2rem;
  /* max-width: 350px !important;
  max-height: 350px !important; */
}
.card-topic:hover {
  transform: scale(1.01);
}

.card-feature {
  overflow: hidden;
  box-shadow: 0 8px 16px 0 rgba(75, 104, 123, 0.2),
    0 2px 4px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  animation: ease-in;
  /* border-inline-width: thick !important;
  writing-mode: horizontal-tb !important;
  border-left-color: #4cae4f !important; */
  border-radius: 5px;
  /* height: 350px; */
  /* border-color: #258493 !important; */
  margin-bottom: 2rem;
  /* max-width: 350px !important;
  max-height: 350px !important; */
  min-height: 30rem;
}
.card-feature:hover {
  transform: scale(1.01);
}

.top-img {
  height: 290px !important;
  max-height: 290px !important;
  object-fit: contain;
}

.ongoing {
  background-color: #0f373c !important;
  /* height: 500px !important;
  max-height: 700px; */
  display: flex;
  padding-top: 3px;
  padding-bottom: 0px;
  /* justify-content: center; */
}
.ongoing-w {
  background-color: #ffffff !important;
  /* height: 500px !important; */
  display: flex;
  padding-top: 10px;
  padding-bottom: 10px;
}
.ong-research {
  display: flex;
  flex-direction: row;
}
.titletopic {
  display: flex;
  justify-content: center;
  text-align: center;
  /* color: white !important; */
}
.titletopic-m {
  display: flex;
  justify-content: center;
  text-align: center;
  color: black !important;
}
.coltxt {
  object-fit: cover !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  flex: 55% !important;
  /* padding-right: 30px; */
}
.colimg {
  object-fit: cover !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  flex: 45% !important;
  max-height: 300px;
}
.ong-txt-h3 {
  color: white !important;
}

.ong-txt-h3-bl {
  color: black !important;
}

.ong-txt {
  margin: 18px !important;
}
.row-ct {
  display: flex;
  flex-direction: row;
}
.col-ct {
  display: flex;
  flex-direction: column;
}
.img-rs {
  max-height: 350px;
  padding: 10px;
}
.caption {
  background-color: rgba(98, 98, 98, 0.643);
   /* width: 100% !important;  */
  text-align: center !important;
  color: white !important;
  padding: 2px !important;
}
.carousel-img {
  width: 100% !important;
  height: 380px !important;
  max-height: 380px !important;
  object-fit: contain !important;
  justify-content: center !important;
  display: flex !important;
  align-items: center !important;
}

.card-horizontal {
  display: flex;
  flex: 1 1 auto;
}

.img-container {
  height: 320px !important;
  object-fit: contain !important;
}

.img-container3{
  height: 220px !important;
  object-fit: contain !important;
}

.img-container2{
  height: 240px !important;
  object-fit: contain !important;
}

.footer {
  /* position: relative;
  bottom: 0; */
  width: 100%;
}
.director {
  justify-content: center !important;
  display: flex !important;
}
.top-page {
  /* max-width: 60rem !important; */
  display: flex !important;
  justify-content: center !important;
  /* background: rgb(0, 0, 0);
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(228, 228, 228, 1) 74%,
    rgba(255, 255, 255, 1) 100%
  ); */
}
.top-row {
  width: 78rem !important;
}
.team-row {
  display: flex;
  flex-direction: row;
}
.phd {
  display: flex;
  flex: 40%;
}
.cop {
  display: flex;

  flex: 60%;
}

.pub-type {
  font-size: 0.8em !important;
  font-weight: 500 !important;
  color: #6a6a6a !important;
}
